export default defineNuxtRouteMiddleware((to) => {
  const { companyId } = to.params

  if (!companyId || typeof companyId === 'undefined') {
    return navigateTo('https://www.loyoly.io', { external: true })
  } else {
    const companyIdParameter = parseInt(to.params.companyId as string, 10)
    if (isNaN(companyIdParameter)) {
      return navigateTo('https://www.loyoly.io', { external: true })
    } else {
      useStoreApp().companyId = companyIdParameter
      useStoreCompany().fetchCompany(companyIdParameter)
    }
  }
})
